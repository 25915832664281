jQuery( function( $ ) {
	$( ".is-tab--bt" ).on( "click", function() {
		var speed = 400;
		var now = $( this ).attr( 'href' );
		var target = $( this.hash );
		if( ! target.length ) { return; }
		$( ".is-tab--wrap" ).each( function() {
			$( this ).hide().removeClass( "is-active" );
		} );
		$( now ).fadeToggle().addClass( "is-active" );
		var targetY = target.offset().top;
		$( 'html, body' ).animate( { scrollTop: targetY }, speed, 'swing' );
		window.history.pushState( null, null, this.hash );
	} );
	return false;
} );
