jQuery( function( $ ) {
	var screenWidth = $( window ).width(); //ウィンドウサイズ取得
	var pcWidth = 769; //PCサイズのブレークポイント
	var timer = false; //タイマー初期値

	$( window ).resize( function() {
		//ウィンドウリサイズ後にのみウィンドウサイズを取得する
		if ( timer !== false ) {
			clearTimeout( timer );
		}
		timer = setTimeout( function() {
			var resizeScreenWidth = $( window ).width(); //ウィンドウサイズ再取得
			if( screenWidth != resizeScreenWidth && resizeScreenWidth >= pcWidth ) {
				if( $( '.p-hamburger' ).find( '.is-hamburger--on' ) ) {
					$( this ).removeClass( 'is-hamburger--on' );
					$( this ).addClass( 'is-hamburger--off' );
					$( '.is-hamburger' ).removeClass( 'is-smtnavi--active' );
					$( '.p-hamburger__menu__item__togglebt' ).removeClass( 'is-hamburger--on' );
					$( '.p-hamburger__menu__item__togglebt' ).addClass( 'is-hamburger--off' );
					$( '.p-gnavi__submenu' ).removeAttr( 'style' );
				}
				$( '.is-hamburger__bt' ).removeClass( 'is-smtnavi--active' );
				$( '.p-hamburger' ).css( 'display', 'flex' );
			} else if(  screenWidth != resizeScreenWidth && resizeScreenWidth < pcWidth  ){
				$( '.is-hamburger__bt' ).removeClass( 'is-smtnavi--active' );
				$( '.p-hamburger__menu__item__togglebt' ).removeClass( 'is-hamburger--on' );
				$( '.p-hamburger__menu__item__togglebt' ).addClass( 'is-hamburger--off' );
				$( '.is-hamburger' ).removeClass( 'is-smtnavi--active' );
				$( '.is-hamburger--on' ).removeClass( 'is-hamburger--off' );
				$( '.p-hamburger' ).css( 'display', 'none' );
				$( '.p-gnavi__submenu' ).css( 'display', 'none' );
				$( '.p-gnavi__submenu' ).css( 'visibility', 'inherit' );
				$( '.p-gnavi__submenu' ).removeAttr( 'style' );
				$( '.p-hamburger' ).removeAttr( 'style' );
			}
		}, 1 );
	} );

	toggleMenu( pcWidth );

	function toggleMenu() {
		$( '.is-hamburger__bt' ).on( 'click', function() {
			$( this ).toggleClass( 'is-smtnavi--active' );
			$( '.is-hamburger' ).slideToggle( 'midium', function() {
				if( $( this ).is( ':visible' ) ) {
					$( this ).css( 'display', 'flex' );
				}
			} );
			$( '.is-hamburger' ).toggleClass( 'is-smtnavi--active' );
			return false;
		} );
		$( '.p-gnavi__submenu' ).prev( '.p-hamburger__menu__item__togglebt' ).on( 'click', function() {
			screenWidth = $( window ).width(); //ウィンドウサイズ再取得
			if( screenWidth < pcWidth ) { //タブレット以下のみスライド
				$( this ).next( '.p-gnavi__submenu' ).slideToggle();
				if( $( this ).hasClass( 'is-hamburger--off' ) ) {
					$( this ).removeClass( 'is-hamburger--off' );
					$( this ).addClass( 'is-hamburger--on' );
				} else {
					$( this ).removeClass( 'is-hamburger--on' );
					$( this ).addClass( 'is-hamburger--off' );
				}
				return false;
			}
		} );
	}
} );
