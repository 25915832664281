jQuery( function( $ ) {
	gmenu_over();
	$( '.p-gnavi__submenu' ).hover(
		function() {
			var pcWidth = 769;
			if( window.innerWidth > pcWidth ) {
				$( this ).prev( '.p-gnavi__item__link' ).children( '.is-gnavi--over--off' ).css( 'display', 'none' );
				$( this ).prev( '.p-gnavi__item__link' ).children( '.is-gnavi--over--on' ).css( 'display', 'inline-block' );
			}
		},
		function() {
			gmenu_over();
		}
	);
	function gmenu_over() {
		var pcWidth = 769;
		$( '.p-gnavi__item' ).hover(
			function() {
				if( window.innerWidth > pcWidth ) {
					$( this ).children( '.p-gnavi__item__link' ).children( '.is-gnavi--over--off' ).css( 'display', 'none' );
					$( this ).children( '.p-gnavi__item__link' ).children( '.is-gnavi--over--on' ).css( 'display', 'inline-block' );
				}
			},
			function() {
				if( window.innerWidth > pcWidth ) {
					$( this ).children( '.p-gnavi__item__link' ).children( '.is-gnavi--over--off' ).css( 'display', 'inline-block' );
					$( this ).children( '.p-gnavi__item__link' ).children( '.is-gnavi--over--on' ).css( 'display', 'none' );
				}
			}
		);
		return false;
	}
} );
