jQuery( function( $ ) {
	var $imgElem = $( '.is-imgchange' ),
		$imgElemTab = $( '.is-imgchange--tablet' ),
		img_sp = '_sp',
		img_pc = '_pc',
		img_tab = '_tab',
		spWidth = 641, //tabletサイズのブレークポイント
		pcWidth = 769, //pcサイズのブレークポイント
		fb_spWidth = 320,
		fb_tbWidth = 641,
		fb_pcWidth = 769;

	// sp <-> pc
	$( document ).ready( function() {
		$imgElem.each( function() {
			var $this = $( this );
			if( window.innerWidth < pcWidth ) {
				$this.attr( 'src', $this.attr( 'src' ).replace( img_pc, img_sp ) );
			} else {
				$this.attr( 'src', $this.attr( 'src' ).replace( img_sp, img_pc ) );
			}
		} );
	} );

	$imgElem.each( function() {
		var $this = $( this );
		function imgSize() {
			if( window.innerWidth < spWidth ) {
				$this.attr( 'src', $this.attr( 'src' ).replace( img_pc, img_sp ) );
			} else {
				$this.attr( 'src', $this.attr( 'src' ).replace( img_sp, img_pc ) );
			}
		}
		$( window ).resize( function() { imgSize(); } );
		// imgSize();
	} );

	// sp <-> tablet
	$( document ).ready( function() {
		$imgElemTab.each( function() {
			var $this = $( this );
			if( window.innerWidth > spWidth ) {
				$this.attr( 'src', $this.attr( 'src' ).replace( img_sp, img_tab ) );
			} else {
				$this.attr( 'src', $this.attr( 'src' ).replace( img_tab, img_sp ) );
			}
		} );
	} );

	$imgElemTab.each( function() {
		var $this = $( this );
		function imgSize() {
			if( window.innerWidth > spWidth ) {
				$this.attr( 'src', $this.attr( 'src' ).replace( img_sp, img_tab ) );
			} else {
				$this.attr( 'src', $this.attr( 'src' ).replace( img_tab, img_sp ) );
			}
		}
		$( window ).resize( function() { imgSize(); } );
		// imgSize();
	} );

	var windowWidth = $( window ).width();
	var htmlStr = $( '.is-fbwidget' ).html();
	var timer = null;
	$( window ).on( 'resize',function() {
		var resizedWidth = $( window ).width();
		if( windowWidth != resizedWidth && resizedWidth < pcWidth ) {
			clearTimeout( timer );
			timer = setTimeout( function() {
				$( '.fb-page' ).attr( 'data-width', fb_spWidth );
				$( '.is-fbwidget' ).html( htmlStr );
				window.FB.XFBML.parse();
				//window.FB.XFBML.parse()で再レンダリングします。
				var windowWidth = $( window ).width();
			}, 500 );
		}
	} );
} );
