jQuery( function( $ ) {
	$( window ).scroll( function() {
		var htmlHeight = $( document ).height();
		var gotop = $( '.is-gotop' );
		var footerH = $( '.p-footer' ).innerHeight() - ( gotop.height() / 2 );
		var windowH = $( window ).height() + $( window ).scrollTop();
		if( htmlHeight - windowH <= footerH ) {
			$( '.p-gotop' ).css( {
				"bottom": footerH,
				"position": "absolute"
			} );
		} else {
			$( '.p-gotop' ).css( {
				"bottom": "15px",
				"position": "fixed"
			} );
		}
	} );
} );
