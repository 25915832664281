jQuery( function( $ ) {
	var spWidth = 641, //tabletサイズのブレークポイント
		pcWidth = 769, //pcサイズのブレークポイント
		$elm = $( '.is-pdf' ),
		$modal = 'is-pctable-link',
		base = [],
		data = [],
		link = [],
		data2;


	$( window ).on( 'load resize',function() {
		$elm.each( function() {
			data.push( $( this ).attr( 'data-modal-target' ) );
			link.push( $( this ).attr( 'href' ) );
			var resizedWidth = $( window ).width();
			var $this = $( this );
			if( resizedWidth > pcWidth ) {
				$elm.each( function() {
					data2 = $( this ).attr( 'href' );
					data2 = data2.replace( '#', "" );
					data2 = data2.replace( '/pdf/beginner/', "" );
					data2 = data2.replace( '.pdf', "" );
					data2 = '#' + data2;
					$( this ).removeAttr( 'target' );
					$( this ).attr( { 'href' : data2 } );
					for( var i = 0; i < data.length; i++ ) {
						$( this ).attr( { 'data-modal-target' : data[i] } );
					}
					$( this ).addClass( $modal );
					$( '.is-pctable-link' ).colorbox( {
						inline: true,
						rel: 'group'
					} );
				} );
			} else {
				// for( var i = 0; i < data.length; i++ ) {
					$elm.each( function() {
						$( this ).removeClass( $modal );
						data2 = $( this ).attr( 'href' );
						data2 = data2.replace( '#', "" );
						data2 = data2.replace( '/pdf/beginner/', "" );
						data2 = data2.replace( '.pdf', "" );
						data2 = '/pdf/beginner/' + data2 + '.pdf';
						$( this ).removeClass( 'cboxElement' );
						$( this ).removeAttr( 'data-modal-target' );
						$( this ).attr( { 'target': '_blank' } );
						$( this ).attr( { 'href' : data2 } );
					} );
				// }
			}
		} );
	} );
} );
