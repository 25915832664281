jQuery( function( $ ) {
	//Gotop
	var speed = 400;
	$( '.p-gotop' ).click( function() {
		$( 'body, html' ).animate( { scrollTop: 0 }, speed, 'swing');
		return false;
	} );
	//anchar loink
	var offsetY = -10;
	$( 'a[href^="#"]' ).click( function() {
		if( $( this ).attr( 'class' ) != 'is-tab--bt' ) {
			var target = $( this.hash );
			if( ! target.length ) {
				return;
			}
			var targetY = target.offset().top + offsetY;
			$( 'html, body' ).animate( { scrollTop: targetY }, speed, 'swing');
			window.history.pushState( null, null, this.hash );
		}
		return false;
	} );
} );
